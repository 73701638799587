<template>
  <ServiceRegistration
    title="mortgage"
    service="mortgage"
    :backlink="backlink"
    nextlink="/mortgage/members"
    :approved="approved"
  />
</template>

<script>
import ServiceRegistration from '@/components/service/Registration.vue'

export default {
  name: 'Mortgage_registration',
  components: { ServiceRegistration },
  props: {
    backlink: { type: String, default: '/' },
    approved: { type: Boolean, default: false }
  }
}
</script>
